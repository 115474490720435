/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { equals as r } from "../core/arrayUtils.js";
import { clone as e } from "../core/lang.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import { writer as n } from "../core/accessorSupport/decorators/writer.js";
import o from "./Extent.js";
import a from "./Geometry.js";
import l from "./Point.js";
import h from "./SpatialReference.js";
import { polygonCentroid as p } from "./support/centroid.js";
import { polygonContainsPoint as c } from "./support/contains.js";
import { isClockwise as u } from "./support/coordsUtils.js";
import { getPolygonExtent as f } from "./support/extentUtils.js";
import { isSelfIntersecting as m } from "./support/intersectsBase.js";
import { project as g } from "./support/webMercatorUtils.js";
import { updateSupportFromPoint as y } from "./support/zmUtils.js";
var d;
function R(t) {
  return !Array.isArray(t[0]);
}
function x(t) {
  return "number" == typeof t[0]?.[0];
}
function j(t) {
  if (!t) return;
  let {
    rings: r,
    hasM: e,
    hasZ: s,
    spatialReference: i
  } = t;
  switch (r ??= [], x(r) && (r = [r]), r[0]?.[0]?.length) {
    case 4:
      s ??= !0, e ??= !0;
      break;
    case 3:
      s ??= !0 !== e, e ??= !s;
      break;
    default:
      s ??= !1, e ??= !1;
  }
  return i ??= h.WGS84, {
    ...t,
    hasM: e,
    hasZ: s,
    rings: r,
    spatialReference: i
  };
}
let w = d = class extends a {
  static fromExtent(t) {
    const r = t.clone().normalize(),
      {
        spatialReference: e
      } = t;
    let s = !1,
      i = !1;
    for (const o of r) o.hasZ && (s = !0), o.hasM && (i = !0);
    const n = {
      rings: r.map(t => {
        const r = [[t.xmin, t.ymin], [t.xmin, t.ymax], [t.xmax, t.ymax], [t.xmax, t.ymin], [t.xmin, t.ymin]];
        if (s && t.hasZ) {
          const e = t.zmin + .5 * (t.zmax - t.zmin);
          for (let t = 0; t < r.length; t++) r[t].push(e);
        }
        if (i && t.hasM) {
          const e = t.mmin + .5 * (t.mmax - t.mmin);
          for (let t = 0; t < r.length; t++) r[t].push(e);
        }
        return r;
      }),
      spatialReference: e
    };
    return s && (n.hasZ = !0), i && (n.hasM = !0), new d(n);
  }
  constructor(t) {
    super(j(t)), this.rings = [], this.type = "polygon";
  }
  get cache() {
    return this.commitProperty("rings"), this.commitProperty("hasZ"), this.commitProperty("hasM"), this.commitProperty("spatialReference"), {};
  }
  get centroid() {
    const t = p(this);
    if (!t || isNaN(t[0]) || isNaN(t[1]) || this.hasZ && isNaN(t[2])) return null;
    const r = new l();
    return r.x = t[0], r.y = t[1], r.spatialReference = this.spatialReference, this.hasZ && (r.z = t[2]), r;
  }
  get extent() {
    const {
        spatialReference: t
      } = this,
      r = f(this);
    if (!r) return null;
    const e = new o(r);
    return e.spatialReference = t, e;
  }
  get isSelfIntersecting() {
    return m(this.rings);
  }
  writeRings(t, r) {
    r.rings = e(this.rings);
  }
  addRing(t) {
    if (!t) return;
    const r = this.rings,
      e = r.length;
    if (R(t)) {
      const s = [];
      for (let r = 0, e = t.length; r < e; r++) s[r] = t[r].toArray();
      r[e] = s;
    } else r[e] = t.slice();
    return this.notifyChange("rings"), this;
  }
  clone() {
    const t = new d();
    return t.spatialReference = this.spatialReference, t.rings = e(this.rings), t.hasZ = this.hasZ, t.hasM = this.hasM, t;
  }
  equals(t) {
    if (this === t) return !0;
    if (null == t) return !1;
    const e = this.spatialReference,
      s = t.spatialReference;
    if (null != e != (null != s)) return !1;
    if (null != e && null != s && !e.equals(s)) return !1;
    if (this.rings.length !== t.rings.length) return !1;
    const i = ([t, r, e, s], [i, n, o, a]) => t === i && r === n && (null == e && null == o || e === o) && (null == s && null == a || s === a);
    for (let n = 0; n < this.rings.length; n++) {
      const e = this.rings[n],
        s = t.rings[n];
      if (!r(e, s, i)) return !1;
    }
    return !0;
  }
  contains(t) {
    if (!t) return !1;
    const r = g(t, this.spatialReference);
    return c(this, null != r ? r : t);
  }
  isClockwise(t) {
    const r = R(t) ? t.map(t => this.hasZ ? this.hasM ? [t.x, t.y, t.z, t.m] : [t.x, t.y, t.z] : [t.x, t.y]) : t;
    return u(r);
  }
  getPoint(t, r) {
    if (!this._validateInputs(t, r)) return null;
    const e = this.rings[t][r],
      s = this.hasZ,
      i = this.hasM;
    return s && !i ? new l(e[0], e[1], e[2], void 0, this.spatialReference) : i && !s ? new l(e[0], e[1], void 0, e[2], this.spatialReference) : s && i ? new l(e[0], e[1], e[2], e[3], this.spatialReference) : new l(e[0], e[1], this.spatialReference);
  }
  insertPoint(t, r, e) {
    return this._validateInputs(t, r, !0) ? (y(this, e), Array.isArray(e) || (e = e.toArray()), this.rings[t].splice(r, 0, e), this.notifyChange("rings"), this) : this;
  }
  removePoint(t, r) {
    if (!this._validateInputs(t, r)) return null;
    const e = new l(this.rings[t].splice(r, 1)[0], this.spatialReference);
    return this.notifyChange("rings"), e;
  }
  removeRing(t) {
    if (!this._validateInputs(t, null)) return null;
    const r = this.rings.splice(t, 1)[0],
      e = this.spatialReference,
      s = r.map(t => new l(t, e));
    return this.notifyChange("rings"), s;
  }
  setPoint(t, r, e) {
    return this._validateInputs(t, r) ? (y(this, e), Array.isArray(e) || (e = e.toArray()), this.rings[t][r] = e, this.notifyChange("rings"), this) : this;
  }
  _validateInputs(t, r, e = !1) {
    if (null == t || t < 0 || t >= this.rings.length) return !1;
    if (null != r) {
      const s = this.rings[t];
      if (e && (r < 0 || r > s.length)) return !1;
      if (!e && (r < 0 || r >= s.length)) return !1;
    }
    return !0;
  }
  toJSON(t) {
    return this.write({}, t);
  }
};
t([s({
  readOnly: !0
})], w.prototype, "cache", null), t([s({
  readOnly: !0
})], w.prototype, "centroid", null), t([s({
  readOnly: !0
})], w.prototype, "extent", null), t([s({
  readOnly: !0
})], w.prototype, "isSelfIntersecting", null), t([s({
  type: [[[Number]]],
  json: {
    write: {
      isRequired: !0
    }
  }
})], w.prototype, "rings", void 0), t([n("rings")], w.prototype, "writeRings", null), w = d = t([i("esri.geometry.Polygon")], w);
const v = w;
w.prototype.toJSON.isDefaultToJSON = !0;
export { v as default };