/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { clone as s } from "../core/lang.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import { writer as r } from "../core/accessorSupport/decorators/writer.js";
import o from "./Extent.js";
import n from "./Geometry.js";
import a from "./Point.js";
import { updateSupportFromPoint as p } from "./support/zmUtils.js";
var h;
function l(t) {
  return (s, e) => null == s ? e : null == e ? s : t(s, e);
}
function c(t) {
  return t && ("esri.geometry.SpatialReference" === t.declaredClass || null != t.wkid);
}
let m = h = class extends n {
  constructor(...t) {
    super(...t), this.points = [], this.type = "multipoint";
  }
  normalizeCtorArgs(t, s) {
    if (!t && !s) return {};
    const e = {};
    Array.isArray(t) ? (e.points = t, e.spatialReference = s) : c(t) ? e.spatialReference = t : (t.points && (e.points = t.points), t.spatialReference && (e.spatialReference = t.spatialReference), t.hasZ && (e.hasZ = t.hasZ), t.hasM && (e.hasM = t.hasM));
    const i = e.points?.[0];
    return i && (void 0 === e.hasZ && void 0 === e.hasM ? (e.hasZ = i.length > 2, e.hasM = !1) : void 0 === e.hasZ ? e.hasZ = i.length > 3 : void 0 === e.hasM && (e.hasM = i.length > 3)), e;
  }
  get cache() {
    return this.commitProperty("points"), this.commitProperty("hasZ"), this.commitProperty("hasM"), this.commitProperty("spatialReference"), {};
  }
  get extent() {
    const t = this.points;
    if (!t.length) return null;
    const s = new o(),
      e = this.hasZ,
      i = this.hasM,
      r = e ? 3 : 2,
      n = t[0],
      a = l(Math.min),
      p = l(Math.max);
    let h,
      c,
      m,
      u,
      [f, y] = n,
      [d, g] = n;
    for (let o = 0, l = t.length; o < l; o++) {
      const s = t[o],
        [n, l] = s;
      if (f = a(f, n), y = a(y, l), d = p(d, n), g = p(g, l), e && s.length > 2) {
        const t = s[2];
        h = a(h, t), m = p(m, t);
      }
      if (i && s.length > r) {
        const t = s[r];
        c = a(c, t), u = p(u, t);
      }
    }
    return s.xmin = f, s.ymin = y, s.xmax = d, s.ymax = g, s.spatialReference = this.spatialReference, e ? (s.zmin = h, s.zmax = m) : (s.zmin = void 0, s.zmax = void 0), i ? (s.mmin = c, s.mmax = u) : (s.mmin = void 0, s.mmax = void 0), s;
  }
  writePoints(t, e) {
    e.points = s(this.points);
  }
  addPoint(t) {
    return p(this, t), Array.isArray(t) ? this.points.push(t) : this.points.push(t.toArray()), this.notifyChange("points"), this;
  }
  clone() {
    const t = {
      points: s(this.points),
      spatialReference: this.spatialReference
    };
    return this.hasZ && (t.hasZ = !0), this.hasM && (t.hasM = !0), new h(t);
  }
  getPoint(t) {
    if (!this._validateInputs(t)) return null;
    const s = this.points[t],
      e = {
        x: s[0],
        y: s[1],
        spatialReference: this.spatialReference
      };
    let i = 2;
    return this.hasZ && (e.z = s[2], i = 3), this.hasM && (e.m = s[i]), new a(e);
  }
  removePoint(t) {
    if (!this._validateInputs(t)) return null;
    const s = new a(this.points.splice(t, 1)[0], this.spatialReference);
    return this.notifyChange("points"), s;
  }
  setPoint(t, s) {
    return this._validateInputs(t) ? (p(this, s), Array.isArray(s) || (s = s.toArray()), this.points[t] = s, this.notifyChange("points"), this) : this;
  }
  toJSON(t) {
    return this.write({}, t);
  }
  _validateInputs(t) {
    return null != t && t >= 0 && t < this.points.length;
  }
};
t([e({
  readOnly: !0
})], m.prototype, "cache", null), t([e()], m.prototype, "extent", null), t([e({
  type: [[Number]],
  json: {
    write: {
      isRequired: !0
    }
  }
})], m.prototype, "points", void 0), t([r("points")], m.prototype, "writePoints", null), m = h = t([i("esri.geometry.Multipoint")], m), m.prototype.toJSON.isDefaultToJSON = !0;
const u = m;
export { u as default };