/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { clone as e } from "../core/lang.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { subclass as r } from "../core/accessorSupport/decorators/subclass.js";
import { writer as i } from "../core/accessorSupport/decorators/writer.js";
import a from "./Extent.js";
import n from "./Geometry.js";
import o from "./Point.js";
import h from "./SpatialReference.js";
import { getPolylineExtent as p } from "./support/extentUtils.js";
import { updateSupportFromPoint as l } from "./support/zmUtils.js";
var c;
function u(t) {
  return !Array.isArray(t[0]);
}
function f(t) {
  return "number" == typeof t[0]?.[0];
}
function m(t) {
  if (!t) return;
  let {
    paths: e,
    hasM: s,
    hasZ: r,
    spatialReference: i
  } = t;
  switch (e ??= [], f(e) && (e = [e]), e[0]?.[0]?.length) {
    case 4:
      r ??= !0, s ??= !0;
      break;
    case 3:
      r ??= !0 !== s, s ??= !r;
      break;
    default:
      r ??= !1, s ??= !1;
  }
  return i ??= h.WGS84, {
    ...t,
    hasM: s,
    hasZ: r,
    paths: e,
    spatialReference: i
  };
}
let y = c = class extends n {
  constructor(t) {
    super(m(t)), this.paths = [], this.type = "polyline";
  }
  get cache() {
    return this.commitProperty("paths"), this.commitProperty("hasZ"), this.commitProperty("hasM"), this.commitProperty("spatialReference"), {};
  }
  get extent() {
    const {
        spatialReference: t
      } = this,
      e = p(this);
    if (!e) return null;
    const s = new a(e);
    return s.spatialReference = t, s;
  }
  writePaths(t, s) {
    s.paths = e(this.paths);
  }
  addPath(t) {
    if (!t) return;
    const e = this.paths,
      s = e.length;
    if (u(t)) {
      const r = [];
      for (let e = 0, s = t.length; e < s; e++) r[e] = t[e].toArray();
      e[s] = r;
    } else e[s] = t.slice();
    return this.notifyChange("paths"), this;
  }
  clone() {
    const t = new c();
    return t.spatialReference = this.spatialReference, t.paths = e(this.paths), t.hasZ = this.hasZ, t.hasM = this.hasM, t;
  }
  getPoint(t, e) {
    if (!this._validateInputs(t, e)) return null;
    const s = this.paths[t][e],
      r = this.hasZ,
      i = this.hasM;
    return r && !i ? new o(s[0], s[1], s[2], void 0, this.spatialReference) : i && !r ? new o(s[0], s[1], void 0, s[2], this.spatialReference) : r && i ? new o(s[0], s[1], s[2], s[3], this.spatialReference) : new o(s[0], s[1], this.spatialReference);
  }
  insertPoint(t, e, s) {
    return this._validateInputs(t, e, !0) ? (l(this, s), Array.isArray(s) || (s = s.toArray()), this.paths[t].splice(e, 0, s), this.notifyChange("paths"), this) : this;
  }
  removePath(t) {
    if (!this._validateInputs(t, null)) return null;
    const e = this.paths.splice(t, 1)[0],
      s = this.spatialReference,
      r = e.map(t => new o(t, s));
    return this.notifyChange("paths"), r;
  }
  removePoint(t, e) {
    if (!this._validateInputs(t, e)) return null;
    const s = new o(this.paths[t].splice(e, 1)[0], this.spatialReference);
    return this.notifyChange("paths"), s;
  }
  setPoint(t, e, s) {
    return this._validateInputs(t, e) ? (l(this, s), Array.isArray(s) || (s = s.toArray()), this.paths[t][e] = s, this.notifyChange("paths"), this) : this;
  }
  _validateInputs(t, e, s = !1) {
    if (null == t || t < 0 || t >= this.paths.length) return !1;
    if (null != e) {
      const r = this.paths[t];
      if (s && (e < 0 || e > r.length)) return !1;
      if (!s && (e < 0 || e >= r.length)) return !1;
    }
    return !0;
  }
  toJSON(t) {
    return this.write({}, t);
  }
};
t([s({
  readOnly: !0
})], y.prototype, "cache", null), t([s({
  readOnly: !0
})], y.prototype, "extent", null), t([s({
  type: [[[Number]]],
  json: {
    write: {
      isRequired: !0
    }
  }
})], y.prototype, "paths", void 0), t([i("paths")], y.prototype, "writePaths", null), y = c = t([r("esri.geometry.Polyline")], y);
const d = y;
y.prototype.toJSON.isDefaultToJSON = !0;
export { d as default };