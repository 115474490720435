/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function t(t) {
  const n = [];
  for (t.reset(); t.nextPath();) {
    const e = [];
    for (; t.nextPoint();) e.push([t.x, t.y]);
    n.push(e);
  }
  return t.reset(), n;
}
function n(t) {
  const n = [];
  for (; t.nextPoint();) n.push([t.x, t.y]);
  return t.seekPathStart(), n;
}
export { t as collectMultipath, n as collectPath };